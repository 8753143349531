// CurrencyLineChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';

function CurrencyLineChart({ selectedCurrency, priceHistory }) {
  if (!selectedCurrency) {
    return <p>Select a cryptocurrency to see its price history.</p>;
  }

  // Generate labels with a timestamp or index
  const chartLabels = priceHistory.map((_, index) => `Point ${index + 1}`);

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: `${selectedCurrency} Price (USD)`,
        data: priceHistory,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      tooltip: {
        callbacks: {
          title: (context) => `Data Point: ${context[0].label}`,
          label: (context) => `Price: $${context.raw.toFixed(2)}`,
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        title: { display: true, text: 'Data Points' },
      },
      y: {
        beginAtZero: false,
        title: { display: true, text: 'Price (USD)' },
        ticks: {
          callback: (value) => `$${value}`, // Format Y-axis with dollar symbol
        },
      },
    },
    animation: {
      duration: 500, // Smooth transition for updating data
    },
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      {priceHistory.length > 0 ? (
        <Line data={chartData} options={options} />
      ) : (
        <p>Loading price history for {selectedCurrency}...</p>
      )}
    </div>
  );
}

export default CurrencyLineChart;