import React, { useEffect, useState } from 'react';
import CurrencyLineChart from './CurrencyLineChart';
import Top20BarChart from './Top20BarChart';

function PriceInfo() {
  const [data, setData] = useState({});
  const [lastUpdate, setLastUpdate] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [priceHistory, setPriceHistory] = useState([]); // Store historical prices for selected currency
  const [top20Currencies, setTop20Currencies] = useState([]);
  const [loading, setLoading] = useState(true);

  // Establish WebSocket connection to receive crypto data updates
  useEffect(() => {
    const socket = new WebSocket('wss://ws.coincap.io/prices?assets=ALL');

    socket.onmessage = (event) => {
      const newData = JSON.parse(event.data);
      setData((prevData) => ({ ...prevData, ...newData }));
      setLastUpdate(new Date().toLocaleTimeString());
      setLoading(false);

      // Update price history if a specific currency is selected
      if (selectedCurrency && newData[selectedCurrency]) {
        setPriceHistory((prevHistory) => [...prevHistory, parseFloat(newData[selectedCurrency])]);
      }
    };

    socket.onclose = () => console.log('WebSocket connection closed');
    socket.onerror = (error) => console.error('WebSocket error:', error);

    return () => socket.close();
  }, [selectedCurrency]);

  // Update top 20 currencies whenever data updates
  useEffect(() => {
    const sortedData = Object.entries(data)
      .sort(([, a], [, b]) => parseFloat(b) - parseFloat(a)) // Sort by value descending
      .slice(0, 20) // Take top 20
      .map(([key, value]) => [key, parseFloat(value)]); // Parse values to float
    setTop20Currencies(Object.fromEntries(sortedData));
  }, [data]);

  // Handle dropdown selection for the line chart
  const handleCurrencySelection = (event) => {
    setSelectedCurrency(event.target.value);
    setPriceHistory([]); // Reset price history when currency changes
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Cryptocurrency Price Tracker</h2>
      
      {/* Last Update Section */}
      <div style={styles.lastUpdateContainer}>
        <span style={styles.lastUpdate}>Last Updated: {lastUpdate}</span>
      </div>

      {/* Dropdown and Line Chart Section */}
      <div style={styles.chartContainer}>
        <div style={styles.dropdownContainer}>
          <label htmlFor="currencySelect" style={styles.label}>Select Cryptocurrency:</label>
          <select
            id="currencySelect"
            onChange={handleCurrencySelection}
            value={selectedCurrency}
            style={styles.select}
          >
            <option value="" disabled>Select a currency</option>
            {Object.keys(data).map((currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </select>
        </div>
        
        <div style={styles.lineChartContainer}>
          <h4 style={styles.sectionHeader}>Price History for {selectedCurrency || 'Selected Currency'}</h4>
          <CurrencyLineChart selectedCurrency={selectedCurrency} priceHistory={priceHistory} />
        </div>
      </div>

      {/* Top 20 Bar Chart Section */}
      <div style={styles.barChartContainer}>
        <h4 style={styles.sectionHeader}>Top 20 Most Valuable Cryptocurrencies</h4>
        <Top20BarChart top20Currencies={top20Currencies} />
      </div>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: '1200px',
    margin: 'auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '24px',
    color: '#333',
  },
  lastUpdateContainer: {
    textAlign: 'center',
    marginBottom: '15px',
  },
  lastUpdate: {
    fontSize: '14px',
    color: '#666',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    marginBottom: '40px',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#fafafa',
  },
  dropdownContainer: {
    textAlign: 'center',
    marginBottom: '10px',
  },
  label: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#333',
  },
  select: {
    width: '100%',
    maxWidth: '400px',
    padding: '10px',
    fontSize: '16px',
    marginTop: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  lineChartContainer: {
    width: '100%',
    textAlign: 'center',
  },
  sectionHeader: {
    fontSize: '18px',
    color: '#555',
    marginBottom: '10px',
  },
  barChartContainer: {
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
  },
};

export default PriceInfo;