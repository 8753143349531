import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import * as d3 from 'd3';

function ChangePercent24Hr() {
  const [data, setData] = useState([]);
  const chartRef = useRef(null);

  useEffect(() => {
    // Fetch data from the API endpoint
    axios.get('https://api.coincap.io/v2/assets/')
      .then(response => {
        if (response.data && response.data.data) {
          const formattedData = response.data.data
            .map(asset => ({
              id: asset.id,
              changePercent24Hr: parseFloat(asset.changePercent24Hr)
            }))
            .filter(asset => !isNaN(asset.changePercent24Hr)); // Filter out invalid data
          setData(formattedData);
        } else {
          console.error('Unexpected data format:', response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      renderChart(data);
    }
  }, [data]);

  const renderChart = (chartData) => {
    const width = 960;
    const height = 960;
    const margin = 50;
    const format = d3.format(".2f");

    const parsedData = chartData.map(item => ({
      id: item.id,
      value: item.changePercent24Hr,
    }));

    if (parsedData.length === 0) {
      console.warn('No valid data to render');
      return;
    }

    // Remove any existing elements to avoid duplicating the chart
    d3.select(chartRef.current).selectAll('*').remove();

    // Set up SVG
    const svg = d3.select(chartRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("style", "max-width: 100%; height: auto; font: 12px sans-serif;");

    const color = d3.scaleSequential()
      .domain([-10, 10]) // Assuming -10% to +10% for demonstration
      .interpolator(d3.interpolateRdYlGn);

    const pack = d3.pack()
      .size([width - margin * 2, height - margin * 2])
      .padding(10);

    const root = d3.hierarchy({ children: parsedData })
      .sum(d => Math.abs(d.value));

    pack(root);

    const nodes = svg.append("g")
      .attr("transform", `translate(${margin},${margin})`)
      .selectAll("g")
      .data(root.leaves())
      .join("g")
      .attr("transform", d => `translate(${d.x},${d.y})`);

    // Circle and Tooltip with Animation
    nodes.append("circle")
      .attr("fill", d => color(d.data.value))
      .attr("r", 0) // Start with radius 0 for animation
      .transition() // Add transition for the circle animation
      .duration(1000)
      .ease(d3.easeElasticOut.amplitude(1).period(0.3)) // Smooth bounce animation
      .attr("r", d => d.r) // Transition to final radius
      .on("end", function() {
        // Tooltip interactions after animation completes
        d3.select(this)
          .on("mouseover", (event, d) => {
            d3.select(event.currentTarget)
              .attr("stroke", "black")
              .attr("stroke-width", 2);
            
            svg.append("foreignObject")
              .attr("x", d.x - 80)
              .attr("y", d.y - d.r - 70)
              .attr("width", 160)
              .attr("height", 80)
              .attr("class", "tooltip")
              .html(`
                <div style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                  background: rgba(255, 255, 255, 0.95);
                  padding: 10px;
                  border-radius: 20px;
                  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
                  font-size: 12px;
                  color: #333;
                  text-align: center;
                  line-height: 1.2;
                  font-family: Arial, sans-serif;
                  word-wrap: break-word;
                  overflow-wrap: break-word;
                  overflow: hidden;
                ">
                  <strong>${d.data.id}</strong>
                  <span>Change (24 Hr): ${format(d.data.value)}%</span>
                </div>
              `);
          })
          .on("mouseout", (event) => {
            d3.select(event.currentTarget)
              .attr("stroke", "none");

            svg.select(".tooltip").remove();
          });
      });

    // Dynamically sized and centered text within the bubbles
    nodes.append("text")
      .attr("dy", "0.3em")
      .style("font-size", d => `${Math.min(16, d.r / 4)}px`)
      .style("fill", "#fff")
      .style("pointer-events", "none")
      .style("text-anchor", "middle")
      .text(d => d.data.id)
      .each(function (d) {
        const text = d3.select(this);
        const textWidth = this.getComputedTextLength();
        if (textWidth > d.r * 2) {
          text.text(d.data.id.substring(0, Math.floor(d.r / 4)) + "...");
        }
      });
  };

  return (
    <div>
      <h2>Price Change Percentage in a 24 Hour time frame. </h2>
      <svg ref={chartRef}></svg>
    </div>
  );
}

export default ChangePercent24Hr;