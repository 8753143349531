// Dashboard.js
import React from 'react';
import CryptoOverview from './CryptoOverview';




const Dashboard = () => {
  return (
    <div style={{
      padding: '10px',
      fontFamily: 'Arial, sans-serif',
      maxWidth: '2000px', 
      margin: 'auto',
      color: '#f0f0f0'
    }}>
      <CryptoOverview /> 
    </div>
  );
};

export default Dashboard;