import React from 'react';

function SupplyInfo() {
  return (
    <div>
      <h2>Celebrity Portfolio</h2>
      <p> </p>
      <p>Coming soon </p>
    </div>
  );
}

export default SupplyInfo;