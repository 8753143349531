// Top20BarChart.js
import React, { useEffect } from 'react';
import * as d3 from 'd3';

function Top20BarChart({ top20Currencies }) {
  useEffect(() => {
    const createD3Chart = () => {
      const width = 928;
      const height = 500;
      const marginTop = 20;
      const marginRight = 30;
      const marginBottom = 50;
      const marginLeft = 50;

      const color = d3.scaleOrdinal(d3.schemeCategory10)
        .domain(Object.keys(top20Currencies));

      const x = d3.scaleBand()
        .domain(Object.keys(top20Currencies))
        .rangeRound([marginLeft, width - marginRight])
        .padding(0.1);

      const y = d3.scaleLinear()
        .domain([0, d3.max(Object.values(top20Currencies), (d) => d)]).nice()
        .range([height - marginBottom, marginTop]);

      // Clear any existing content and create SVG element
      const svg = d3.select("#d3-chart-container").html("")
        .append("svg")
        .attr("viewBox", `0 0 ${width} ${height}`)
        .attr("width", width)
        .attr("height", height)
        .attr("style", "max-width: 100%; height: auto;");

      // Create a tooltip div that is hidden by default
      const tooltip = d3.select("#d3-chart-container")
        .append("div")
        .style("position", "absolute")
        .style("background", "#ffffff") // White background for contrast
        .style("border", "1px solid #ccc")
        .style("padding", "8px")
        .style("border-radius", "4px")
        .style("color", "#000") // Black text
        .style("font-size", "12px")
        .style("pointer-events", "none")
        .style("opacity", 0);

      // Render bars and add event listeners for mouse events
      svg.append("g")
        .selectAll("rect")
        .data(Object.entries(top20Currencies))
        .join("rect")
          .attr("fill", ([name]) => color(name))
          .attr("x", ([name]) => x(name))
          .attr("y", ([, value]) => y(value))
          .attr("width", x.bandwidth())
          .attr("height", ([, value]) => y(0) - y(value))
          .on("mouseover", function (event, [name, value]) {
            tooltip
              .style("opacity", 1)
              .html(`<strong>${name}</strong>: $${value.toFixed(2)}`);
            d3.select(this).attr("fill", "orange"); // Highlight bar on hover
          })
          .on("mousemove", (event) => {
            tooltip
              .style("left", (event.pageX - 30) + "px")  // Position horizontally centered
              .style("top", (event.pageY - 50) + "px");   // Position above the bar
          })
          .on("mouseout", function () {
            tooltip.style("opacity", 0); // Hide tooltip
            d3.select(this).attr("fill", ([name]) => color(name)); // Reset bar color
          });

      // X-axis
      svg.append("g")
        .attr("transform", `translate(0,${height - marginBottom})`)
        .call(d3.axisBottom(x).tickSizeOuter(0))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-0.8em")
        .attr("dy", "0.15em")
        .attr("transform", "rotate(-40)")
        .style("fill", "black"); // Set x-axis text color to black

      // Y-axis
      svg.append("g")
        .attr("transform", `translate(${marginLeft},0)`)
        .call(d3.axisLeft(y).ticks(10, "$s"))
        .selectAll("text")
        .style("fill", "black"); // Set y-axis text color to black
    };

    if (Object.keys(top20Currencies).length > 0) {
      createD3Chart();
    }
  }, [top20Currencies]);

  return <div id="d3-chart-container" style={{ marginTop: '50px', position: 'relative' }}></div>;
}

export default Top20BarChart;