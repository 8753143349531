// Home.js
import React from 'react';

const Home = () => {
  return (
    <div className="home">
      <img src={`${process.env.PUBLIC_URL}/icon.svg`} alt="Alkeme UX Logo" className="animated-icon" />
      <h1>Welcome to Alkeme UX</h1>
      <p>
        At Alkeme UX, we are building a cutting-edge platform to bring the pinnacle of technological innovation right to your fingertips. 
        From advanced data visualization to 3D printing and system design, we strive to provide an interactive environment where technology meets creativity.
        Our mission is to cultivate a community of forward-thinkers and game-changers, united by a passion for innovation and a vision for the future.
      </p>
    </div>
  );
};

export default Home;